/** ========== */
import Head from "next/head";
import Script from "next/script";
import "styles/globals.scss";

const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>
          Your Great Travel Companion to Amazing Vietnam | ARRIVALHUB
        </title>
        <meta
          name="description"
          content="Looking for Vietnam travel consultant to make your dream trip? ArrivalHub offers tailored packages, 24/7 support, insider tips to make the most of your time. Contact us!"
        />
      </Head>
      <Component {...pageProps} />
      {process.env.NEXT_PUBLIC_GTM_ENABLE && (
        <>
          <Script strategy="lazyOnload" id="googletagmanager">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}

            {`var Tawk_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];s1.async=true;s1.src='${process.env.NEXT_PUBLIC_SRC_TAWKTO}';s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0)})();
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_API.onPrechatSubmit = function(data){var name;var email;for(var i=0;i<data.length;i++){if(data[i].label=="Name"){name=data[i].answer}else if(data[i].label=="Email"){email=data[i].answer}}window.dataLayer=window.dataLayer||[];window.dataLayer.push({'event':'tawkto','name':name,'email':email,});
            };
            Tawk_API.onOfflineSubmit = function(data){
              var data_offline=data.questions;var name;var email;for(var i=0;i<data_offline.length;i++){if(data_offline[i].label=="Name"){name=data_offline[i].answer}else if(data_offline[i].label=="Email"){email=data_offline[i].answer}}window.dataLayer=window.dataLayer||[];window.dataLayer.push({'event':'tawkto','name':name,'email':email,});
            };
            Tawk_API.onChatMaximized=function(){document.getElementsByClassName("hidd")[0].style.display="block"};
            Tawk_API.onChatMinimized=function(){document.getElementsByClassName("hidd")[0].style.display="none"}`}
          </Script>
        </>
      )}
    </>
  );
};

export default MyApp;
